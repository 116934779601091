import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { colorTokens } from '@/components/ui/colorTokens';

export const AppleStoreIcon: FCIcon = (props) => (
  <svg width="207" height="61" viewBox="0 0 207 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_3035_74174)">
      <path d="M199.333 0H7.66667C3.43248 0 0 3.41383 0 7.625V53.375C0 57.5862 3.43248 61 7.66667 61H199.333C203.568 61 207 57.5862 207 53.375V7.625C207 3.41383 203.568 0 199.333 0Z" fill="black" />
      <path d="M43.3398 29.6802C43.3584 28.2523 43.7419 26.8522 44.4547 25.6103C45.1674 24.3684 46.1862 23.325 47.4163 22.5771C46.6349 21.4734 45.604 20.5651 44.4055 19.9243C43.207 19.2835 41.874 18.928 40.5122 18.8858C37.6074 18.5843 34.7913 20.6048 33.3111 20.6048C31.8022 20.6048 29.5231 18.9158 27.0689 18.9657C25.4815 19.0164 23.9344 19.473 22.5784 20.2908C21.2224 21.1086 20.1038 22.2599 19.3315 23.6324C15.986 29.3607 18.4814 37.7793 21.6861 42.4096C23.2895 44.6769 25.1634 47.2096 27.6154 47.1198C30.0148 47.0213 30.9109 45.6066 33.8072 45.6066C36.6766 45.6066 37.5173 47.1198 40.0191 47.0626C42.5937 47.0213 44.2159 44.7852 45.763 42.4964C46.9151 40.8809 47.8015 39.0954 48.3896 37.206C46.8938 36.5803 45.6174 35.533 44.7194 34.1947C43.8214 32.8564 43.3416 31.2863 43.3398 29.6802Z" fill="white" />
      <path d="M38.6144 15.8407C40.0183 14.1741 40.7099 12.032 40.5424 9.8692C38.3977 10.092 36.4166 11.1057 34.9938 12.7084C34.2981 13.4913 33.7654 14.4022 33.4259 15.3889C33.0864 16.3756 32.9469 17.4188 33.0153 18.4588C34.0881 18.4697 35.1494 18.2398 36.1192 17.7863C37.0891 17.3328 37.9422 16.6676 38.6144 15.8407Z" fill="white" />
      <path d="M73.67 41.3799H65.482L63.5156 47.1219H60.0475L67.8031 25.8779H71.4063L79.1619 47.1219H75.6346L73.67 41.3799ZM66.33 38.7302H72.8203L69.6208 29.4114H69.5313L66.33 38.7302Z" fill="white" />
      <path d="M95.9113 39.3784C95.9113 44.1915 93.3064 47.2839 89.3754 47.2839C88.3796 47.3354 87.3892 47.1085 86.5178 46.6293C85.6463 46.1501 84.9289 45.4378 84.4477 44.5741H84.3733V52.2457H81.1586V31.6333H84.2703V34.2094H84.3294C84.8327 33.3498 85.5623 32.6413 86.4404 32.1591C87.3185 31.6769 88.3124 31.4392 89.3162 31.4712C93.2911 31.4713 95.9113 34.5786 95.9113 39.3784ZM92.607 39.3784C92.607 36.2426 90.9684 34.181 88.4682 34.181C86.012 34.181 84.3598 36.286 84.3598 39.3784C84.3598 42.4992 86.012 44.5891 88.4682 44.5891C90.9684 44.5891 92.607 42.5426 92.607 39.3784Z" fill="white" />
      <path d="M113.149 39.3784C113.149 44.1915 110.544 47.2839 106.613 47.2839C105.617 47.3354 104.627 47.1085 103.755 46.6293C102.884 46.15 102.167 45.4378 101.685 44.5741H101.611V52.2456H98.3962V31.6333H101.508V34.2094H101.567C102.07 33.3498 102.8 32.6413 103.678 32.1591C104.556 31.6769 105.55 31.4392 106.554 31.4712C110.529 31.4712 113.149 34.5786 113.149 39.3784ZM109.845 39.3784C109.845 36.2426 108.206 34.181 105.706 34.181C103.25 34.181 101.598 36.286 101.598 39.3784C101.598 42.4991 103.25 44.5891 105.706 44.5891C108.206 44.5891 109.845 42.5426 109.845 39.3784H109.845Z" fill="white" />
      <path d="M124.541 41.2027C124.78 43.3094 126.849 44.6926 129.677 44.6926C132.387 44.6926 134.336 43.3094 134.336 41.4098C134.336 39.7609 133.16 38.7736 130.376 38.0969L127.592 37.4337C123.648 36.4915 121.817 34.6671 121.817 31.7067C121.817 28.0413 125.047 25.5237 129.633 25.5237C134.172 25.5237 137.284 28.0413 137.389 31.7067H134.143C133.949 29.5867 132.177 28.307 129.587 28.307C126.998 28.307 125.226 29.6017 125.226 31.4862C125.226 32.9881 126.357 33.8719 129.126 34.5484L131.493 35.1231C135.9 36.1539 137.731 37.9048 137.731 41.0122C137.731 44.9866 134.53 47.4759 129.439 47.4759C124.675 47.4759 121.458 45.0451 121.251 41.2026L124.541 41.2027Z" fill="white" />
      <path d="M144.669 27.9679V31.6333H147.648V34.1509H144.669V42.6896C144.669 44.0161 145.266 44.6342 146.575 44.6342C146.929 44.6281 147.282 44.6036 147.633 44.5607V47.0633C147.044 47.172 146.446 47.2213 145.847 47.2103C142.676 47.2103 141.44 46.0325 141.44 43.0287V34.1509H139.162V31.6333H141.439V27.9679H144.669Z" fill="white" />
      <path d="M149.373 39.3785C149.373 34.5052 152.275 31.4429 156.8 31.4429C161.341 31.4429 164.23 34.5052 164.23 39.3785C164.23 44.2651 161.356 47.314 156.8 47.314C152.246 47.314 149.373 44.2651 149.373 39.3785ZM160.954 39.3785C160.954 36.0355 159.405 34.0625 156.8 34.0625C154.195 34.0625 152.648 36.0506 152.648 39.3785C152.648 42.7348 154.195 44.6928 156.8 44.6928C159.405 44.6928 160.954 42.7348 160.954 39.3785H160.954Z" fill="white" />
      <path d="M166.88 31.6333H169.946V34.2696H170.021C170.228 33.4462 170.716 32.7186 171.402 32.209C172.088 31.6994 172.931 31.4389 173.788 31.4713C174.158 31.47 174.528 31.5098 174.889 31.5899V34.5636C174.422 34.4223 173.934 34.3574 173.445 34.3715C172.978 34.3528 172.512 34.4342 172.08 34.6102C171.648 34.7862 171.259 35.0526 170.941 35.3912C170.623 35.7297 170.383 36.1324 170.237 36.5715C170.09 37.0106 170.042 37.4757 170.095 37.935V47.1218H166.88L166.88 31.6333Z" fill="white" />
      <path d="M189.711 42.5727C189.279 45.3844 186.51 47.314 182.968 47.314C178.412 47.314 175.584 44.2951 175.584 39.4519C175.584 34.5937 178.427 31.4429 182.833 31.4429C187.166 31.4429 189.89 34.3865 189.89 39.0827V40.172H178.829V40.3641C178.778 40.9341 178.85 41.5084 179.041 42.0487C179.231 42.589 179.536 43.083 179.934 43.498C180.333 43.9129 180.816 44.2392 181.352 44.4553C181.888 44.6714 182.464 44.7722 183.042 44.7512C183.802 44.8216 184.564 44.6476 185.216 44.2551C185.867 43.8626 186.373 43.2725 186.659 42.5727L189.711 42.5727ZM178.844 37.95H186.674C186.703 37.4375 186.624 36.9246 186.443 36.4435C186.262 35.9624 185.982 35.5236 185.622 35.1545C185.261 34.7853 184.827 34.4939 184.347 34.2985C183.867 34.1031 183.351 34.008 182.833 34.019C182.309 34.0159 181.79 34.1154 181.306 34.3117C180.821 34.508 180.381 34.7972 180.01 35.1627C179.639 35.5281 179.345 35.9626 179.145 36.441C178.945 36.9194 178.843 37.4323 178.844 37.95V37.95Z" fill="white" />
      <path d="M65.9269 9.88756C66.6009 9.83973 67.2772 9.94042 67.907 10.1824C68.5369 10.4243 69.1046 10.8015 69.5692 11.2867C70.0338 11.7719 70.3837 12.3531 70.5937 12.9882C70.8038 13.6234 70.8686 14.2967 70.7836 14.9596C70.7836 18.2207 69.0014 20.0953 65.9269 20.0953H62.1986V9.88756H65.9269ZM63.8017 18.6517H65.7478C66.2294 18.6801 66.7114 18.602 67.1586 18.4231C67.6058 18.2441 68.0071 17.9688 68.3332 17.6171C68.6592 17.2654 68.9019 16.8463 69.0435 16.3902C69.1851 15.9341 69.2221 15.4525 69.1518 14.9804C69.217 14.5103 69.1762 14.0316 69.0325 13.5789C68.8887 13.1261 68.6454 12.7104 68.3201 12.3615C67.9948 12.0126 67.5954 11.7393 67.1507 11.5609C66.706 11.3826 66.227 11.3038 65.7478 11.3301H63.8017V18.6517Z" fill="white" />
      <path d="M72.5945 16.2401C72.5456 15.7339 72.6041 15.2231 72.7664 14.7405C72.9288 14.258 73.1913 13.8143 73.5372 13.438C73.883 13.0617 74.3046 12.761 74.7749 12.5552C75.2451 12.3495 75.7537 12.2432 76.2679 12.2432C76.7821 12.2432 77.2907 12.3495 77.761 12.5552C78.2312 12.761 78.6528 13.0617 78.9987 13.438C79.3445 13.8143 79.6071 14.258 79.7694 14.7405C79.9317 15.2231 79.9903 15.7339 79.9413 16.2401C79.9912 16.7469 79.9333 17.2583 79.7714 17.7417C79.6095 18.225 79.3472 18.6695 79.0012 19.0465C78.6553 19.4236 78.2334 19.7249 77.7626 19.9311C77.2919 20.1373 76.7828 20.2439 76.2679 20.2439C75.7531 20.2439 75.2439 20.1373 74.7732 19.9311C74.3025 19.7249 73.8806 19.4236 73.5346 19.0465C73.1886 18.6695 72.9263 18.225 72.7644 17.7417C72.6025 17.2583 72.5446 16.7469 72.5945 16.2401ZM78.3601 16.2401C78.3601 14.5703 77.6016 13.5938 76.2704 13.5938C74.9342 13.5938 74.1825 14.5703 74.1825 16.2401C74.1825 17.9233 74.9343 18.8923 76.2704 18.8923C77.6016 18.8922 78.3601 17.9166 78.3601 16.2401H78.3601Z" fill="white" />
      <path d="M89.7072 20.0951H88.1125L86.5026 14.4215H86.381L84.7779 20.0951H83.1984L81.0513 12.3917H82.6105L84.0059 18.2698H84.1207L85.7222 12.3917H87.197L88.7984 18.2698H88.92L90.3086 12.3917H91.8459L89.7072 20.0951Z" fill="white" />
      <path d="M93.6516 12.3918H95.1314V13.6155H95.2463C95.4412 13.176 95.7699 12.8075 96.1865 12.5615C96.6032 12.3155 97.0871 12.2042 97.5708 12.2431C97.9498 12.2149 98.3304 12.2714 98.6843 12.4084C99.0382 12.5454 99.3564 12.7595 99.6153 13.0346C99.8743 13.3098 100.067 13.639 100.18 13.998C100.293 14.3569 100.323 14.7364 100.267 15.1082V20.095H98.7297V15.49C98.7297 14.252 98.1857 13.6364 97.0488 13.6364C96.7915 13.6245 96.5346 13.6678 96.2958 13.7634C96.057 13.8589 95.8418 14.0043 95.6652 14.1897C95.4885 14.3752 95.3545 14.5962 95.2723 14.8376C95.1901 15.079 95.1616 15.3352 95.1889 15.5885V20.0951H93.6516L93.6516 12.3918Z" fill="white" />
      <path d="M102.716 9.38458H104.254V20.0951H102.716V9.38458Z" fill="white" />
      <path d="M106.391 16.2401C106.342 15.7338 106.4 15.223 106.563 14.7405C106.725 14.2579 106.988 13.8142 107.333 13.4379C107.679 13.0616 108.101 12.7609 108.571 12.5551C109.042 12.3493 109.55 12.243 110.064 12.243C110.579 12.243 111.087 12.3493 111.558 12.5551C112.028 12.7609 112.449 13.0616 112.795 13.4379C113.141 13.8142 113.404 14.2579 113.566 14.7405C113.729 15.223 113.787 15.7338 113.738 16.2401C113.788 16.7469 113.73 17.2584 113.568 17.7417C113.406 18.225 113.144 18.6695 112.798 19.0466C112.452 19.4236 112.03 19.7249 111.559 19.9311C111.088 20.1373 110.579 20.2438 110.064 20.2438C109.55 20.2438 109.04 20.1373 108.57 19.9311C108.099 19.7249 107.677 19.4236 107.331 19.0466C106.985 18.6695 106.723 18.225 106.561 17.7417C106.399 17.2584 106.341 16.7469 106.391 16.2401ZM112.156 16.2401C112.156 14.5703 111.398 13.5938 110.067 13.5938C108.73 13.5938 107.979 14.5703 107.979 16.2401C107.979 17.9233 108.73 18.8923 110.067 18.8923C111.398 18.8923 112.156 17.9166 112.156 16.2401H112.156Z" fill="white" />
      <path d="M115.357 17.9166C115.357 16.53 116.401 15.7306 118.254 15.617L120.364 15.4967V14.8318C120.364 14.0181 119.82 13.5587 118.769 13.5587C117.911 13.5587 117.316 13.8703 117.146 14.4149H115.657C115.814 13.0918 117.073 12.2431 118.84 12.2431C120.793 12.2431 121.894 13.2045 121.894 14.8318V20.0951H120.414V19.0125H120.293C120.046 19.4009 119.699 19.7174 119.288 19.9299C118.877 20.1424 118.416 20.2432 117.953 20.222C117.626 20.2557 117.296 20.2212 116.983 20.1208C116.67 20.0203 116.382 19.8562 116.138 19.639C115.893 19.4218 115.697 19.1563 115.563 18.8596C115.428 18.563 115.358 18.2417 115.357 17.9166ZM120.364 17.2584V16.6143L118.462 16.7346C117.389 16.8056 116.902 17.1665 116.902 17.8456C116.902 18.5389 117.511 18.9424 118.347 18.9424C118.592 18.9669 118.839 18.9424 119.075 18.8704C119.31 18.7984 119.528 18.6803 119.716 18.5232C119.904 18.3661 120.059 18.1731 120.17 17.9559C120.281 17.7386 120.347 17.5014 120.364 17.2584Z" fill="white" />
      <path d="M123.915 16.2401C123.915 13.806 125.18 12.264 127.148 12.264C127.635 12.2418 128.118 12.3571 128.541 12.5964C128.964 12.8357 129.309 13.1892 129.537 13.6154H129.651V9.38458H131.189V20.0951H129.716V18.878H129.594C129.349 19.3014 128.992 19.6504 128.56 19.8873C128.129 20.1242 127.641 20.24 127.148 20.222C125.166 20.2221 123.915 18.6801 123.915 16.2401ZM125.503 16.2401C125.503 17.874 126.281 18.8572 127.584 18.8572C128.879 18.8572 129.68 17.8598 129.68 16.2468C129.68 14.6413 128.871 13.6297 127.584 13.6297C126.29 13.6297 125.502 14.6196 125.502 16.2401H125.503Z" fill="white" />
      <path d="M137.549 16.2401C137.5 15.7339 137.559 15.2231 137.721 14.7405C137.883 14.258 138.146 13.8143 138.492 13.438C138.837 13.0617 139.259 12.761 139.729 12.5552C140.2 12.3495 140.708 12.2432 141.222 12.2432C141.737 12.2432 142.245 12.3495 142.715 12.5552C143.186 12.761 143.607 13.0617 143.953 13.438C144.299 13.8143 144.561 14.258 144.724 14.7405C144.886 15.2231 144.945 15.7339 144.896 16.2401C144.946 16.7469 144.888 17.2583 144.726 17.7417C144.564 18.225 144.302 18.6695 143.956 19.0465C143.61 19.4236 143.188 19.7249 142.717 19.9311C142.246 20.1373 141.737 20.2439 141.222 20.2439C140.708 20.2439 140.198 20.1373 139.728 19.9311C139.257 19.7249 138.835 19.4236 138.489 19.0465C138.143 18.6695 137.881 18.225 137.719 17.7417C137.557 17.2583 137.499 16.7469 137.549 16.2401ZM143.315 16.2401C143.315 14.5703 142.556 13.5938 141.225 13.5938C139.889 13.5938 139.137 14.5703 139.137 16.2401C139.137 17.9233 139.889 18.8923 141.225 18.8923C142.556 18.8922 143.315 17.9166 143.315 16.2401Z" fill="white" />
      <path d="M146.958 12.3918H148.438V13.6155H148.553C148.748 13.176 149.077 12.8075 149.493 12.5615C149.91 12.3155 150.394 12.2042 150.878 12.2431C151.257 12.2149 151.637 12.2714 151.991 12.4084C152.345 12.5454 152.663 12.7595 152.922 13.0346C153.181 13.3098 153.374 13.639 153.487 13.998C153.6 14.3569 153.629 14.7364 153.574 15.1082V20.095H152.036V15.49C152.036 14.252 151.492 13.6364 150.356 13.6364C150.098 13.6245 149.841 13.6678 149.603 13.7634C149.364 13.8589 149.149 14.0043 148.972 14.1897C148.795 14.3752 148.661 14.5962 148.579 14.8376C148.497 15.079 148.468 15.3352 148.496 15.5885V20.0951H146.958V12.3918Z" fill="white" />
      <path d="M162.26 10.4739V12.4269H163.948V13.7074H162.26V17.6685C162.26 18.4754 162.596 18.8288 163.362 18.8288C163.557 18.8281 163.753 18.8164 163.948 18.7937V20.06C163.672 20.1089 163.392 20.1348 163.112 20.1377C161.402 20.1377 160.721 19.5429 160.721 18.0577V13.7073H159.485V12.4268H160.721V10.4739H162.26Z" fill="white" />
      <path d="M166.047 9.38458H167.571V13.6297H167.693C167.897 13.1861 168.235 12.8155 168.66 12.5686C169.085 12.3217 169.577 12.2103 170.068 12.2497C170.445 12.2295 170.822 12.2917 171.172 12.4321C171.522 12.5724 171.836 12.7874 172.092 13.0617C172.348 13.336 172.54 13.6628 172.654 14.0189C172.768 14.375 172.801 14.7515 172.751 15.1216V20.0951H171.212V15.4966C171.212 14.2662 170.632 13.643 169.546 13.643C169.282 13.6216 169.016 13.6575 168.767 13.7483C168.519 13.839 168.293 13.9825 168.106 14.1685C167.919 14.3545 167.776 14.5786 167.686 14.8252C167.596 15.0718 167.561 15.3349 167.585 15.596V20.095H166.048L166.047 9.38458Z" fill="white" />
      <path d="M181.714 18.0151C181.505 18.7191 181.053 19.3281 180.436 19.7356C179.819 20.143 179.077 20.3229 178.339 20.2437C177.826 20.2571 177.316 20.1599 176.844 19.9589C176.373 19.7578 175.952 19.4577 175.609 19.0794C175.267 18.7011 175.012 18.2536 174.862 17.7681C174.712 17.2826 174.671 16.7705 174.741 16.2676C174.672 15.7632 174.714 15.2501 174.864 14.7631C175.014 14.2761 175.267 13.8265 175.608 13.4449C175.948 13.0633 176.368 12.7585 176.838 12.5511C177.308 12.3438 177.818 12.2387 178.332 12.243C180.5 12.243 181.807 13.7074 181.807 16.1264V16.6569H176.307V16.7421C176.283 17.0248 176.319 17.3093 176.412 17.5775C176.506 17.8457 176.655 18.0915 176.85 18.2993C177.046 18.5071 177.283 18.6722 177.546 18.7841C177.81 18.8959 178.094 18.9521 178.381 18.9489C178.749 18.9926 179.121 18.9271 179.451 18.7608C179.781 18.5945 180.054 18.3349 180.234 18.0151L181.714 18.0151ZM176.307 15.5325H180.241C180.26 15.274 180.225 15.0143 180.137 14.7702C180.049 14.526 179.91 14.3028 179.729 14.1148C179.549 13.9269 179.33 13.7784 179.088 13.6789C178.846 13.5794 178.586 13.531 178.324 13.537C178.058 13.5337 177.794 13.583 177.548 13.6822C177.301 13.7813 177.078 13.9282 176.89 14.1141C176.702 14.3001 176.553 14.5214 176.453 14.765C176.353 15.0086 176.303 15.2696 176.307 15.5325H176.307Z" fill="white" />
    </g>
    <rect x="0.588571" y="0.588571" width="205.823" height="59.8229" rx="3.41143" stroke={colorTokens.border} strokeWidth="1.17714" />
    <defs>
      <clipPath id="clip0_3035_74174">
        <rect width="207" height="61" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
