import { sendAmplitudeData } from '@/controllers/amplitude/amplitude.helpers';
import { GoogleAnalyticsClient } from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';

export const NativeAppEvents = {
  sendEvent: (event: string, data: Record<string, any>) => {
    sendAmplitudeData(event, data);
    GoogleAnalyticsClient.getInstance().sendEvent(event, data);
  },
  events: {
    appStoreButtonClicked: 'app_store_button_clicked',
  },
};
